import React from "react";
import i18n from "../../../i18n";
//import swal from "sweetalert";
import Select from "react-select";
import swal from "sweetalert";
import { createWallet } from "../../../models/wallet";
import FileInputNew from "../../helpers/fileInputNew";

const selectStylesPop = {
  control: (provided) => ({
    ...provided,
  }),
  option: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  singleValue: (provided) => ({
    ...provided,
    textAlign: "justify",
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
};

export class AddWalletFund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      input: {},
      errors: {},
      users: [],
      fileInputs: [],
      auctions: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    let { auctions, group } = this.props;
    console.log(auctions);
    const aucs = auctions.map((item) => {
      return {
        value: item?.id,
        label: item?.title,
        group: item?.group,
        users: item?.participants,
      };
    });
    this.setState({ auctions: aucs });
    let { input } = this.state;
    input.group = group.id;
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  onFileChange = (event) => {
    if (event.target.name === "file_receipt") {
      this.setState({ file_receipt: event.target.files[0] });
    }
  };

  onFileChangeNew = (e, index) => {
    const updatedInputs = [...this.state.fileInputs];
    updatedInputs[index].file = e.target.files[0];
    this.setState({ fileInputs: updatedInputs });
  };

  addNewFileInput = () => {
    this.setState((prevState) => ({
      fileInputs: [
        ...prevState.fileInputs,
        { id: prevState.fileInputs.length + 1, file: null },
      ],
    }));
  };

  async handleSubmit(event) {
    event.preventDefault();
    if (
      !this.state.input.payment_date ||
      !this.state.input.user ||
      !this.state.input.credit ||
      !this.state.file_receipt ||
      !this.state.input.account_number ||
      !this.state.input.bank ||
      !this.state.input.receipt_number ||
      !this.state.input.reference
    ) {
      swal(i18n.t("failed"), i18n.t("all_mandatory"), "error");
      return;
    }
    try {
      this.setState({ is_loading: true });
      let { fileInputs } = this.state;
      const formData = new FormData();
      formData.append("credit", this.state.input.credit);
      formData.append("account_number", this.state.input.account_number);
      formData.append("bank", this.state.input.bank);
      formData.append("receipt_number", this.state.input.receipt_number);
      formData.append("auction", this.state.input.auction);
      formData.append("group", this.state.input.group);
      formData.append("payment_date", this.state.input.payment_date);
      formData.append("method", "offline");
      formData.append("status", "P");
      formData.append("type", "client_payment");
      formData.append("user", this.state.input.user);
      if (this.state.file_receipt) {
        formData.append(
          "file_receipt",
          this.state.file_receipt,
          this.state.file_receipt.name
        );
      }

      if (fileInputs.length > 0) {
        fileInputs.forEach((input, index) => {
          if (input.file) {
            formData.append(
              `file_receipt_addl_${index}`,
              input.file,
              input.file.name
            );
          }
        });
      }

      const response = await createWallet(formData);
      if (response && response.success) {
        if (response.success) {
          swal(i18n.t("success"), i18n.t("fund_added"), "success");
          this.setState({ is_loading: false });
        } else {
          swal(
            i18n.t("failed"),
            "Some errors occured. please contact administrator",
            "error"
          );
          this.setState({ is_loading: false });
          return;
        }
      } else {
        this.setState({ is_loading: false });
      }
    } catch (err) {
      console.log(err);
    }
  }

  removeFile = (index) => {
    this.setState((prevState) => ({
      fileInputs: prevState.fileInputs.filter((_, i) => i !== index),
    }));
  };

  render() {
    let { users, is_loading, auctions } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="single-page">
            <div className="col-md-12 d-block mx-auto">
              <div className="wrapper wrapper2">
                <form
                  id="addFund"
                  className="card-body"
                  tabindex="500"
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-6 mt-1">
                      <label>{i18n.t("auction")}</label>
                      <Select
                        options={auctions}
                        onChange={(e) => {
                          let { input } = this.state;
                          input.auction = e.value;
                          this.setState({ input });
                          let _users = e.users;
                          let users = _users.map((item) => {
                            return {
                              value: item?.user_id,
                              label: item?.name + " (" + item?.username + ")",
                            };
                          });
                          this.setState({ users });
                        }}
                        key={0}
                        loadingMessage={"Loading users"}
                        name="user"
                        styles={selectStylesPop}
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        className="form-control"
                      ></Select>
                      <div className="text-danger">
                        {this.state.errors.user}
                      </div>
                    </div>
                    <div className="col-6 mt-1">
                      <label>{i18n.t("bidder")}</label>
                      <Select
                        options={users}
                        onChange={(e) => {
                          let { input } = this.state;
                          input.user = e.value;
                          this.setState({ input });
                        }}
                        key={0}
                        loadingMessage={"Loading users"}
                        name="user"
                        styles={selectStylesPop}
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        className="form-control"
                      ></Select>
                      <div className="text-danger">
                        {this.state.errors.user}
                      </div>
                    </div>

                    <div className="col-6 mt-1">
                      <label>{i18n.t("payment_date")}</label>
                      <input
                        type="date"
                        name="payment_date"
                        value={this.state.input.payment_date}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                      <div className="text-danger">
                        {this.state.errors.payment_date}
                      </div>
                    </div>

                    <div className="col-6 mt-1">
                      <label>{i18n.t("amount")}</label>
                      <input
                        type="text"
                        name="credit"
                        value={this.state.input.credit}
                        onChange={this.handleChange}
                        autoComplete="credit"
                        className="form-control"
                      />

                      <div className="text-danger">
                        {this.state.errors.credit}
                      </div>
                    </div>

                    <div className="col-6 mt-1">
                      <label>{i18n.t("account_number")}</label>
                      <input
                        type="text"
                        name="account_number"
                        value={this.state.input.account_number}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                      <div className="text-danger">
                        {this.state.errors.account_number}
                      </div>
                    </div>

                    <div className="col-6 mt-1">
                      <label>{i18n.t("bank")}</label>
                      <input
                        type="text"
                        name="bank"
                        value={this.state.input.bank}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                      <div className="text-danger">
                        {this.state.errors.bank}
                      </div>
                    </div>

                    <div className="col-6 mt-1">
                      <label>{i18n.t("receipt")}</label>
                      <input
                        type="text"
                        name="receipt_number"
                        value={this.state.input.receipt_number}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                      <div className="text-danger">
                        {this.state.errors.receipt_number}
                      </div>
                    </div>

                    <div className="col-12 mt-1">
                      <div className="custom-text3">{i18n.t("receipt")}</div>
                      <div className="form-group">
                        <FileInputNew
                          wrapperClass=""
                          placeholder={i18n.t("choose_file")}
                          id="file_receipt"
                          name="file_receipt"
                          onChange={this.onFileChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <label className="custom-text3">
                        {i18n.t("additional_doc")}
                      </label>
                      {this.state.fileInputs.map((input, index) => (
                        <div key={input.id} className="row mb-2">
                          <div className="col-3"></div>
                          <div className="col-12 d-flex justify-content-between align-items-center">
                            <div className="flex-grow-1 me-2">
                              <FileInputNew
                                wrapperClass={`bg-white ${
                                  !this.state.fileInputs[index].file
                                    ? "is-empty"
                                    : ""
                                }`}
                                className="form-control custom-input rounded-pill"
                                placeholder={i18n.t("choose_file")}
                                help_text=""
                                name="file_receipt_addl"
                                id={`file_receipt_addl_${index}`}
                                onChange={(e) => this.onFileChangeNew(e, index)}
                              />
                            </div>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm rounded-circle"
                              style={{ padding: "0px 6px" }}
                              onClick={() => this.removeFile(index)}
                            >
                              &times;
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="row">
                        <div className="col-3"></div>
                        <div className="col-12 d-flex justify-content-start align-items-center">
                          <button
                            className="btn btn-sm btn-info"
                            style={{ height: "90%" }}
                            onClick={this.addNewFileInput}
                            type="button"
                          >
                            <i className="fa fa-plus" />
                            &nbsp;{i18n.t("add_new")}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="reference mt-3">
                      <label>{i18n.t("remarks")}</label>
                      <textarea
                        name="reference"
                        value={this.state.input.reference}
                        onChange={this.handleChange}
                        rows={5}
                        className="form-control"
                      ></textarea>

                      <div className="text-danger">
                        {this.state.errors.reference}
                      </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-center mt-2">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          this.props.onClose();
                        }}
                      >
                        <i className="fa fa-times-circle" />
                        &nbsp;
                        {i18n.t("cancel")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.handleSubmit}
                      >
                        {is_loading && <i className="fa fa-spinner fa-spin" />}
                        {!is_loading && <i className="fa fa-save" />}
                        &nbsp;
                        {i18n.t("submit")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
