import React from "react";
import { SideBar } from "./sidebar";
import {
  getAuctions,
  deleteAuction,
  getAllBidders,
  statuses,
  updateInvoiceHelpers,
} from "../../models/auctions";
import { getAllCategories } from "../../models/categories";
import { getAllGroups } from "../../models/groups";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import { getLoggedUser } from "../../state/user";
import { MzadLoader } from "../helpers/mzadLoader";
import { HomeBanner } from "../home/homeBanner";
import { getAllOrganization } from "../../models/organization";
const user = getLoggedUser();

function objectToQueryString(obj) {
  const queryString = Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
  return queryString;
}
export class AuctionsList extends React.Component {
  constructor() {
    super();
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    this.state = {
      is_loading: false,
      auctions: {},
      open_popup: false,
      categories: {},
      groups: {},
      clients: [],
      totalRows: 0,
      currentPage: 1,
      input: {
        category: "",
        type: params.type,
        section: "main",
        page: 1,
        limit: 15,
      },
      rem_amount: "",
      service_inv_link: "",
      remarks_popup: false,
      remarks: {
        amount: "",
        remark: "",
      },
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#e4f7ff",
            color: "#000000",
          },
        },
      },
      selected_id: null,
      columns: [
        {
          id: 1,
          name: "ID",
          sortable: true,
          wrap: true,
          width: "100px",
          cell: (row) =>
            user.role === "A" ? (
              <a href={"/edit_auction/" + row.id}>{row.id}</a>
            ) : (
              <span>{row.id}</span>
            ),
        },
        {
          id: 2,
          name: "Edit",
          button: true,
          cell: (row) =>
            user.role === "A" ? (
              <a href={"/edit_auction/" + row.id} className="btn btn-info">
                Edit
              </a>
            ) : (
              <span>NA</span>
            ),
        },
        {
          id: 3,
          name: i18n.t("bid_counr"),
          button: true,
          cell: (row) => (
            <button
              className="btn btn-success"
              disabled={row.bid_count > 0 ? false : true}
              onClick={(event) => {
                this.state.open_popup = true;
                this.openBidPopup(row.id);
              }}
            >
              {row.bid_count}
            </button>
          ),
        },
        {
          id: 4,
          name: i18n.t("title"),
          cell: (row) => row.title,
          sortable: true,
        },
        {
          id: 5,
          name: i18n.t("status"),
          sortable: true,
          cell: (row) => statuses[row.status],
        },
        {
          id: 6,
          name: i18n.t("price"),
          cell: (row) => row.start_amount,
        },
        {
          id: 7,
          name: i18n.t("incr_amount"),
          cell: (row) => row.bid_increment,
          wrap: true,
        },
        {
          id: 8,
          name: i18n.t("start_date"),
          cell: (row) => row.start_date,
          wrap: true,
        },
        {
          id: 9,
          name: i18n.t("end_date"),
          cell: (row) => row.end_date,
          wrap: true,
        },
        {
          id: 10,
          name: i18n.t("invoice") + "(" + i18n.t("bidder") + ")",
          button: true,
          wrap: true,
          cell: (row) =>
            row.winner_file && (
              <div className="d-flex">
                <a
                  className="btn btn-primary btn-sm"
                  href={row.winner_file.link_ar}
                  target="_new"
                >
                  {i18n.t("Ar")}
                </a>
                &nbsp;
                <a
                  className="btn btn-light btn-sm"
                  href={row.winner_file.link_en}
                  target="_new"
                >
                  {i18n.t("En")}
                </a>
              </div>
            ),
        },
        {
          id: 11,
          name: i18n.t("invoice") + "(" + i18n.t("client") + ")",
          button: true,
          wrap: true,
          cell: (row) =>
            row.winner_file && (
              <div className="d-flex">
                <a
                  className="btn btn-warning btn-sm"
                  href={row.winner_file.link_ar + "&is_client=1"}
                  target="_new"
                >
                  {i18n.t("Ar")}
                </a>
                &nbsp;
                <a
                  className="btn btn-info btn-sm"
                  href={row.winner_file.link_en + "&is_client=1"}
                  target="_new"
                >
                  {i18n.t("En")}
                </a>
              </div>
            ),
        },
        {
          id: 12,
          name: i18n.t("invoice") + "(" + i18n.t("service") + ")",
          button: true,
          wrap: true,
          cell: (row) => {
            let amount =
              (row.start_amount * row.group.client_service_charge) / 100;
            return (
              row.winner_file && (
                <div className="d-flex">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      this.remarksPopup(
                        row.winner_file.link_en + "&is_client=1&is_service=1",
                        amount,
                        row
                      );
                    }}
                  >
                    {i18n.t("download")}
                  </button>
                </div>
              )
            );
          },
        },
      ],

      user_columns: [
        {
          id: 1,
          name: "ID",
          cell: (row) => row.id,
          sortable: true,
          wrap: true,
          width: "100px",
        },
        {
          id: 4,
          name: i18n.t("title"),
          cell: (row) => row.title,
          sortable: true,
        },
        {
          id: 5,
          name: i18n.t("status"),
          sortable: true,
          cell: (row) => statuses[row.status],
        },
        {
          id: 6,
          name: i18n.t("price"),
          cell: (row) => row.start_amount,
        },
        {
          id: 7,
          name: i18n.t("incr_amount"),
          cell: (row) => row.bid_increment,
          wrap: true,
        },
        {
          id: 8,
          name: i18n.t("start_date"),
          cell: (row) => row.start_date,
          wrap: true,
        },
        {
          id: 9,
          name: i18n.t("end_date"),
          cell: (row) => row.end_date,
          wrap: true,
        },
      ],
      bidder_loader: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleRemarkChange = this.handleRemarkChange.bind(this);
    this.loadAllAuctions = this.loadAllAuctions.bind(this);
    this.remarksPopup = this.remarksPopup.bind(this);
    this.updateInveHelpers = this.updateInveHelpers.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let { input } = this.state;
    let qstring =
      params.type === "click"
        ? new URLSearchParams(params).toString()
        : objectToQueryString(input);
    this.loadAllCategories();
    this.loadAllOrganisation();
    this.loadAllAuctions(qstring);

    if (user.role === "U") {
      let columns = this.state.columns;
      delete columns[1];
      delete columns[2];
      this.setState({ columns });
    }

    if (user.role === "C" || user.role === "M") {
      let { user_columns } = this.state;
      let bid_column = {
        id: 3,
        name: i18n.t("bid_counr"),
        button: true,
        cell: (row) => (
          <button
            className="btn btn-success"
            disabled={row.bid_count > 0 ? false : true}
            onClick={(event) => {
              this.setState({ open_popup: true });
              this.openBidPopup(row.id);
            }}
          >
            {row.bid_count}
          </button>
        ),
      };
      user_columns.splice(1, 0, bid_column);
      this.setState({ user_columns });
    }
  }

  async loadAllOrganisation() {
    const response = await getAllOrganization();
    let clients = response.data;
    this.setState({ clients });
  }

  handlePageChange = (page) => {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let { input } = this.state;
    input["page"] = page;
    this.setState({ currentPage: page });
    this.setState({ input: input });
    this.setState({ query: params });
    let qstring = new URLSearchParams(input).toString();
    this.loadAllAuctions(qstring);
  };

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "client") {
      this.loadAllGroups();
    }
  }

  handleRemarkChange(event) {
    let { remarks } = this.state;
    remarks[event.target.name] = event.target.value;
    this.setState({ remarks });
  }

  deleteRow(id) {
    const response = deleteAuction(id);
    if (response.success) {
      swal(i18n.t("success"), "Auction deleted", "success", {
        buttons: false,
        timer: 2000,
      })
        .then((response) => {
          window.location.reload();
        })
        .catch((response) => {
          swal("Failed", "Something went wrong", "error");
        });
    }
  }

  async openBidPopup(auction) {
    this.setState({ bidders: {}, bidder_loader: true });
    const response = await getAllBidders({ auction });
    let bidders = response.data;
    if (bidders && bidders.length > 0) {
      this.setState({ open_popup: true, bidders, bidder_loader: false });
    }
  }

  async loadAllCategories() {
    const response = await getAllCategories();
    let categories = response.data;
    this.setState({ categories });
  }

  async loadAllGroups() {
    this.setState({ group_loading: true });
    let { client } = this.state.input;
    let q = client ? `organization=${client}` : "";
    const response = await getAllGroups(q);
    if (response.success) {
      let groups = response.data;
      this.setState({ groups });
      this.setState({ group_loading: false });
    } else {
      this.setState({ group_loading: false });
    }
  }

  async loadAllAuctions(params) {
    this.setState({ is_loading: true });
    //let { input } = this.state;
    //const queryString = new URLSearchParams(params).toString();
    // let q = "";
    // if (queryString !== "") {
    //   q = "?" + queryString;
    // }
    // if (params.type !== "click") {
    //   var type = params.type;
    //   var section = params.section;
    //   let { input } = this.state;
    //   input.type = type;
    //   input.section = section;
    //   q = "?limit=" + input.limit + "&page=" + input.page;
    //   if (type) {
    //     q += "&type=" + type;
    //   }
    //   if (section) {
    //     q += "&section=" + section;
    //   }
    //   this.setState({ input });
    // }
    const response = await getAuctions("?" + params);
    if (response) {
      let auctions = response.data;
      let meta = response.meta;
      this.setState({ totalRows: meta.total });
      this.setState({ auctions: auctions, is_loading: false });
    }
  }

  remarksPopup(link, amt, row) {
    if (row.inv_amount_words && row.inv_remarks && row.inv_title) {
      window.open(link, "_new");
      return;
    }
    this.setState({ service_inv_link: link, rem_amount: amt });
    this.setState({ remarks_popup: true });
    this.setState({ selected_id: row.id });
  }

  formatQueryNewLine(str) {
    str = encodeURIComponent(str);
    return str.replace(/%0A/g, "\\n");
  }

  updateInveHelpers() {
    let { remarks, selected_id } = this.state;
    let payload = {
      id: selected_id,
      inv_amount_words: remarks.amount,
      inv_title: remarks.title,
      inv_remarks: remarks.remark,
    };
    const response = updateInvoiceHelpers(payload);
    if (response) {
      let auction = response.data;
      console.log(auction);
      let params = {
        type: "",
      };
      this.loadAllAuctions(params);

      swal(i18n.t("success"), "Details updated", "success", {
        buttons: false,
        timer: 2000,
      })
        .then((response) => {
          this.setState({ remarks_popup: false });
          let url = this.state.service_inv_link;
          window.open(url, "_new");
        })
        .catch((response) => {
          swal("Failed", "Something went wrong", "error");
        });
    }
  }

  render() {
    let { auctions, clients, categories, groups, group_loading } = this.state;
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header d-flex">
                    <span
                      style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                      onClick={() => {
                        this.setState({ hideSidebar: !this.state.hideSidebar });
                      }}
                    >
                      {this.state.hideSidebar && (
                        <i className="icon-size-actual"></i>
                      )}
                      {!this.state.hideSidebar && (
                        <i className="icon-size-fullscreen"></i>
                      )}
                    </span>

                    <h3 className="card-title">{i18n.t("auctions")}</h3>
                  </div>
                  <div className="search-bar">
                    <div className="row m-3">
                      {/* <div className="col-sm-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Section</label>
                          <select
                            className="form-control"
                            data-placeholder="Select section"
                            name="section"
                            value={this.state.input.section}
                            onChange={this.handleChange}
                          >
                            <option value="">--Select--</option>
                            <option value="main">Main</option>
                            <option value="charity">Charity</option>
                          </select>
                        </div>
                      </div> */}
                      <div className="col-sm-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("auction_type")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select type"
                            name="type"
                            value={this.state.input.type}
                            onChange={this.handleChange}
                          >
                            <option value="">--Select--</option>
                            <option value="active">
                              {i18n.t("active_auctions")}
                            </option>
                            <option value="previous">
                              {i18n.t("previous_auctions")}
                            </option>
                            <option value="featured">
                              {i18n.t("featured_auctions")}
                            </option>
                            <option value="upcoming">
                              {i18n.t("upcoming_auctions")}
                            </option>
                            <option value="direct">
                              {i18n.t("direct_sale")}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("auction_category")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Category"
                            name="category"
                            value={this.state.input.category}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("category")}--</option>
                            {categories.length > 0 &&
                              categories.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.category_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("client")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Client"
                            name="client"
                            value={this.state.input.client}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("client")}--</option>
                            {clients.length > 0 &&
                              clients.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.organization_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("auction_lots")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Group"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            {group_loading && (
                              <option selected={true}>
                                {i18n.t("loading")}...
                              </option>
                            )}
                            {!group_loading && (
                              <option value="" selected={true}>
                                --{i18n.t("lots")}--
                              </option>
                            )}
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            className="btn btn-primary btn-block rounded-5"
                            type="button"
                            onClick={() => {
                              let { input } = this.state;
                              let qstring = objectToQueryString(input);
                              this.loadAllAuctions(qstring);
                            }}
                          >
                            {i18n.t("search")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* {!this.state.is_loading && ( */}
                  <div className="card-body">
                    {this.state.is_loading && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "rgba(255, 255, 255, 0.7)",
                          zIndex: "99",
                        }}
                      >
                        <MzadLoader
                          animation="grow"
                          size="md"
                          loading={this.state.is_loading}
                          style={{ position: "relative" }}
                        />
                      </div>
                    )}
                    <div className="table-responsive ">
                      {auctions.length > 0 && (
                        <div className="table-wrapper">
                          <DataTable
                            responsive={true}
                            title=""
                            fixedHeader
                            columns={
                              user.role === "A"
                                ? this.state.columns
                                : this.state.user_columns
                            }
                            data={auctions}
                            customStyles={this.state.customStyles}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={this.state.totalRows}
                            paginationPerPage={this.state.input.limit}
                            paginationDefaultPage={this.state.currentPage}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            paginationComponentOptions={{
                              rowsPerPageText: "Auctions per page:",
                              rangeSeparatorText: i18n.t("out"),
                            }}
                            onChangePage={this.handlePageChange}
                            onChangeRowsPerPage={(size) => {
                              this.setState({ rows_per_page: 15 });
                              let { input } = this.state;
                              input.limit = size;
                              this.setState({ input });
                              this.handlePageChange(1);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* )} */}
                  {!this.state.is_loading && auctions.length === 0 && (
                    <div className="m-3 text-center p-3 text-red">
                      {i18n.t("no_auctions_found")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          show={this.state.open_popup}
          onHide={() => this.setState({ open_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Top Bidders
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MzadLoader loading={this.state.bidder_loader} />
            {this.state.bidders && this.state.bidders.length > 0 && (
              <table className="table table-stripped">
                <thead>
                  <tr>
                    <th>
                      {i18n.t("name")} & {i18n.t("details")}
                    </th>
                    <th>{i18n.t("rank")}</th>
                    <th>{i18n.t("amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.bidders.map((item, i) => {
                    return (
                      <tr>
                        <td>
                          {item.name}(
                          <small className="text text-dark">
                            USER ID : {item.user_id}
                          </small>
                          )<br />
                          <small className="text text-dark">
                            {i18n.t("bidder_id")} : {item.id}
                          </small>
                          {(user.role === "A" || user.role === "M") && (
                            <span>
                              <br />
                              <small className="text text-dark">
                                {item.email}
                              </small>
                              <br />
                              <small className="text text-dark">
                                {item.mobile}
                              </small>
                            </span>
                          )}
                        </td>
                        <td>
                          <span className="badge badge-pill badge-info me-2">
                            {i + 1}
                          </span>
                        </td>
                        <td>{item.bid_amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.remarks_popup}
          onHide={() => this.setState({ remarks_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Enter details for displaying in invoice
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3">
              <div className="col-sm-12 col-md-12">
                <div className="form-group">
                  <label className="form-label">
                    Amount in words
                    {/* ({this.state.rem_amount}) */}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="amount"
                    value={this.state.remarks.amount}
                    onChange={this.handleRemarkChange}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-12 col-md-12">
                <div className="form-group">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    value={this.state.remarks.title}
                    onChange={this.handleRemarkChange}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-12 col-md-12">
                <div className="form-group">
                  <label className="form-label">Remarks</label>
                  <textarea
                    className="form-control"
                    name="remark"
                    value={this.state.remarks.remark}
                    onChange={this.handleRemarkChange}
                    rows={5}
                    maxLength={500}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-12 col-md-12">
                <div className="form-group text-center">
                  <button
                    className="btn btn-outline-danger"
                    onClick={this.updateInveHelpers}
                  >
                    {i18n.t("submit")} & {i18n.t("download")}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
